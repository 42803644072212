// GENERAL
// ---------------

$color1:              #1a4821;
$color2:              #80a45e;
$color3:              #45474d;
$color4:              #f3f8fc;

// TYPOGRAPHY
// ---------------

$textColor:           #45474d;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Roboto", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #DDD;
